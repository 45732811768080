<template>
  <div class="p-fluid">
      <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" />
      <div class="card">
          銘柄コードリスト (Comma Separator)
          <Chips v-model="meigaralist" separator="," />
          <Button label="検索" @click="submitMeigaralist()" />
      </div>
      <Menu ref="menu1" :popup="true" :model="items"></Menu>
      <div class="card">
            meigaracompare mode1 {{perf1}}
            <DataTable :value="meigaras" class="p-datatable-sm"
            responsiveLayout="scroll" showGridlines stripedRows
            dataKey="id" :paginator="true" :rows="50"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :globalFilterFields="['industry_name']"
            selectionMode="single"
            :loading="loading">
                <!-- <Column field="id" header="No" :sortable="true"></Column> -->
                <Column>
                  <template #body="slotProps">
                    <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="toggle_setMeigaraCd($event, slotProps.data.meigara_cd, slotProps.data.meigara_name)"></Button>
                  </template>
                </Column>
                <Column field="meigara_cd" header="銘柄コード" :sortable="true"></Column>
                <Column field="meigara_name" header="銘柄名" :sortable="true"></Column>    
                <Column field="trendchart_file" header="トレンド">  
                  <template #body="slotProps">
                    <router-link :to="{name:'meigarachart',params:{code:slotProps.data.meigara_cd}}">
                      <img class="trendchart-img" :src="'https://tradersr.com' + slotProps.data.trendchart_file" :alt="slotProps.data.trendchart_file"/>
                    </router-link>
                  </template>
                </Column>                     
                <Column field="ago_today_diff" header="前日比" :sortable="true">
                  <template #body="slotProps">
                    <div :class="priceClass(slotProps.data)">
                      {{slotProps.data.ago_today_diff}}
                    </div>
                  </template>
                </Column>
                <Column field="ago_today_ratio" header="前日比%" :sortable="true">
                  <template #body="slotProps">
                    <div :class="priceClass(slotProps.data)">
                      {{slotProps.data.ago_today_ratio}}
                    </div>
                  </template>                    
                </Column>
                <Column field="settlement_date" header="settlement_date" :sortable="true"></Column>               
                <Column field="high" header="高値" :sortable="true">
                </Column>
                <Column field="low" header="安値" :sortable="true">
                </Column>
                <Column field="open" header="始値" :sortable="true">    
                </Column>               
                <Column field="close" header="終値" :sortable="true"> 
                </Column>
                <Column field="volume" header="出来高" :sortable="true"></Column>
                <Column field="adj_close" header="調整後終値" :sortable="true"></Column>
                <Column field="volume_ratio" header="出来高前日比%" :sortable="true"></Column>
                <Column field="price_date" header="日付" :sortable="true" :style="{'min-width': '10rem'}"></Column>               
                <Column field="price_timestamp" header="時刻" :sortable="true"></Column>
                <Column field="info_source" header="情報源" :sortable="true"></Column>
                <Column field="per" header="per" :sortable="true"></Column>
                <Column field="pbr" header="pbr" :sortable="true"></Column>
                <Column field="yield" header="yield" :sortable="true"></Column>
                <Column field="margin_ratio" header="margin_ratio" :sortable="true"></Column>
                <Column field="market_value_str" header="market_value_str"></Column>
                <Column field="market_value" header="market_value" :sortable="true"></Column>
                <Column field="rela_meigara_cd_list" header="rela_meigara_cd_list" :sortable="true"></Column>
                <Column field="rela_meigara_name_list" header="rela_meigara_name_list" :sortable="true"></Column>
                <Column field="rela_at_ratio" header="rela_at_ratio" :sortable="true"></Column>
                <Column field="rstrength" header="rstrength" :sortable="true">
                  <template #body="slotProps">
                    <div :class="rstrength_priceClass(slotProps.data)">
                      {{slotProps.data.rstrength}}
                    </div>
                  </template>                    
                </Column>
                <Column field="cont_class" header="cont_class" :sortable="true"></Column>
                <Column field="taishaku_kubun" header="taishaku_kubun" :sortable="true"></Column>
                <Column field="market_name" header="market_name" :sortable="true"></Column>
                <Column field="market_at_ratio" header="market_at_ratio" :sortable="true"></Column>
                <Column field="mrstrength" header="mrstrength" :sortable="true">
                  <template #body="slotProps">
                    <div :class="mrstrength_priceClass(slotProps.data)">
                      {{slotProps.data.mrstrength}}
                    </div>
                  </template>
                </Column>
                <Column field="ma5" header="ma5" :sortable="true"></Column>
                <Column field="ma5_dratio" header="ma5_dratio" :sortable="true">
                  <template #body="slotProps">
                    <div :class="ma5dratio_priceClass(slotProps.data)">
                      {{slotProps.data.ma5_dratio}}
                    </div>
                  </template>
                </Column>
                <Column field="ma10" header="ma10" :sortable="true"></Column>
                <Column field="ma10_dratio" header="ma10_dratio" :sortable="true">
                  <template #body="slotProps">
                    <div :class="ma10dratio_priceClass(slotProps.data)">
                      {{slotProps.data.ma10_dratio}}
                    </div>
                  </template>                    
                </Column>
                <Column field="ma25" header="ma25" :sortable="true"></Column>
                <Column field="ma25_dratio" header="ma25_dratio" :sortable="true">
                  <template #body="slotProps">
                    <div :class="ma25dratio_priceClass(slotProps.data)">
                      {{slotProps.data.ma25_dratio}}
                    </div>
                  </template>                    
                </Column>
                <Column field="ma50" header="ma50" :sortable="true"></Column>
                <Column field="ma50_dratio" header="ma50_dratio" :sortable="true">
                  <template #body="slotProps">
                    <div :class="ma50dratio_priceClass(slotProps.data)">
                      {{slotProps.data.ma50_dratio}}
                    </div>
                  </template>                     
                </Column>
                <Column field="ma75" header="ma75" :sortable="true"></Column>
                <Column field="ma75_dratio" header="ma75_dratio" :sortable="true">
                  <template #body="slotProps">
                    <div :class="ma75dratio_priceClass(slotProps.data)">
                      {{slotProps.data.ma75_dratio}}
                    </div>
                  </template>
                </Column>
                <Column field="gap_ratio" header="gap_ratio" :sortable="true">
                  <template #body="slotProps">
                    <div :class="gapratio_priceClass(slotProps.data)">
                      {{slotProps.data.gap_ratio}}
                    </div>
                  </template>                    
                </Column>
                <Column field="vola_ratio" header="vola_ratio" :sortable="true">
                  <template #body="slotProps">
                    <div :class="volaratio_priceClass(slotProps.data)">
                      {{slotProps.data.vola_ratio}}
                    </div>
                  </template>                     
                </Column>
            </DataTable>
        </div>

        <div class="card">
          銘柄コードリスト (Comma Separator)
          <Chips v-model="meigaralist" separator="," />
          <Button label="実行" @click="submitMeigaralist2()" />

          meigaracompare mode2 {{perf2}}
          <DataTable :value="relations" class="p-datatable-sm"
					responsiveLayout="scroll" showGridlines stripedRows
          :paginator="true" :rows="50"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					selectionMode="single"
          v-model:expandedRows="expandedRows"
          :loading="loading2">
					<!-- <template #header>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="キーワード" />
						</span>
					</template> -->
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="meigaras_cnt" header="銘柄数" :sortable="true"></Column>
					<Column field="rtype" header="Rタイプ" :sortable="true"></Column>
					<Column field="rtypename" header="Rタイプ名" :sortable="true"></Column>
					<Column field="rcd" header="Rコード" :sortable="true"></Column>
					<Column field="rname" header="R名称" :sortable="true"></Column> 
          <Column field="rdate" header="日付" :sortable="true"></Column>     
          <Column field="rtime" header="時刻" :sortable="true"></Column>
          <template #expansion="slotProps">
            <div class="industrytrend-subtable">
              <h5>R名称：{{slotProps.data.rname}}</h5>
              <DataTable :value="slotProps.data.meigaras" responsiveLayout="scroll"
                selectionMode="single">
                <Column field="market_name_short" header="市場" sortable></Column>
                <Column field="meigara_cd" header="コード" sortable></Column>
                <Column field="meigara_name" header="銘柄" sortable></Column>
                <Column field="settlement_date" header="決算日" sortable></Column>
                <Column field="close" header="close" sortable></Column>
                <Column field="ago_today_diff" header="atdiff" sortable></Column>
                <Column field="ago_today_ratio" header="atratio" sortable></Column>
                <Column field="volume_ratio" header="vratio" sortable></Column>
                <Column field="per" header="per" sortable></Column>
                <Column field="pbr" header="pbr" sortable></Column>
                <Column field="yield" header="yield" sortable></Column>
                <Column field="margin_ratio" header="mratio" sortable></Column>
                <Column field="market_value_str" header="時価総額"></Column>
                <Column field="market_value" header="mvalue" sortable></Column>
                <Column field="rstrength" header="rstrength" sortable></Column>
                <Column field="mrstrength" header="mrstrength" sortable></Column>
                <Column field="taishaku_kubun" header="貸借区分" sortable></Column>
                <Column field="price_date" header="date" sortable></Column>
                <Column field="price_timestamp" header="time" sortable></Column>                
                <Column field="ma5_dratio" header="ma5dr" sortable></Column>
                <Column field="ma10_dratio" header="ma10dr" sortable></Column>
                <Column field="ma25_dratio" header="ma25dr" sortable></Column>
                <Column field="ma50_dratio" header="ma50dr" sortable></Column>
                <Column field="ma75_dratio" header="ma75dr" sortable></Column>
                <Column field="gap_ratio" header="gapratio" sortable></Column>
                <Column field="vola_ratio" header="volaratio" sortable></Column>
                <Column field="cont_class" header="cont_class" sortable></Column>
              </DataTable>
            </div>
          </template>
				</DataTable>          

      </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
      return {
          loading: false,
          loading2: false,
          totalRecords: 0,
          meigaralist: null,
          meigaras: null,
          perf1: null,
          perf2: null,
          relations: null,
          expandedRows: [],
          items: [
          {
            label: '銘柄をブックマーク',
            command: () => {
              // this.$router.push('/meigarachart/' + this.selectedMeigaraCd);

              let param_bookmark = {
                userid: 'tradersr',
                bookmark_type: 'M',
                bookmark_group: null,
                bookmark_cd: this.selectedMeigaraCd,
                bookmark_name_ja: this.selectedMeigaraName,
                bookmark_name_en: null,
                bookmark_desc: null,
                public_flag: null,
                public_category: null,
                u_date: null,
                passcode: 'Admin1234!!!!'
              };

              axios.post('https://api.tradersr.com/api/bookmarkscrud', param_bookmark)
              .then(response => {
                console.log(response);
                if(response.data == 'existing'){
                  this.$toast.add({severity:'warn', summary: 'ブックマーク', detail:this.selectedMeigaraCd + ' ' + this.selectedMeigaraName +' は既に登録されています', life: 3000});
                }else{
                  this.$toast.add({severity:'success', summary: 'ブックマーク', detail:this.selectedMeigaraCd + ' ' + this.selectedMeigaraName +' をブックマークしました', life: 3000});
                }
              })
              .catch(e => {
                console.log(e);
                this.$toast.add({severity:'error', summary: 'ブックマーク',detail:e, life: 3000});
              })
            }
          },
          {
            label: '銘柄拡張検索',
            command: () => {
              this.$router.push('/meigarasearch_ext/' + this.selectedMeigaraCd);
            }
          },
          {
            label: '銘柄ニュース',
            command: () => {
              this.$router.push('/meigaranewsrecentl/gridm' + this.selectedMeigaraCd);
            }
          },
          {
            label: '銘柄チャート',
            command: () => {
              this.$router.push('/meigarachart/' + this.selectedMeigaraCd);
            }
          },
        ],  
      }
  },
  methods: {
    submitMeigaralist() {
      if(this.meigaralist && this.meigaralist.length > 0){
        console.log(this.meigaralist);
        this.loading = true;
        axios.post('https://api.tradersr.com/api/meigaracompare', {mode:1, meigaralist:this.meigaralist})
        .then(response => {
          console.log(response);
          this.meigaras = response.data.data;
          this.perf1 = response.data.perf;
          this.loading = false;
        })
      }
    },
    submitMeigaralist2() {
      if(this.meigaralist && this.meigaralist.length > 0){
        console.log(this.meigaralist);
        this.loading2 = true;
        axios.post('https://api.tradersr.com/api/meigaracompare', {mode:2, meigaralist:this.meigaralist})
        .then(response => {
          console.log(response);
          this.relations = response.data.data;
          this.perf2 = response.data.perf;
          this.loading2 = false;
        })
      }
    },
    priceClass(data) {
          return [
            {
              'positive': data.ago_today_diff > 0,
              'negative': data.ago_today_diff < 0,
              'neutral': data.ago_today_diff == 0,
            }
          ];
        },
    rstrength_priceClass(data) {
      return [
        {
          'positive': data.rstrength > 0,
          'negative': data.rstrength < 0,
          'neutral': data.rstrength == 0,
        }
      ];
    },        
    mrstrength_priceClass(data) {
      return [
        {
          'positive': data.mrstrength > 0,
          'negative': data.mrstrength < 0,
          'neutral': data.mrstrength == 0,
        }
      ];
    },
    ma5dratio_priceClass(data) {
      return [
        {
          'positive': data.ma5_dratio > 0,
          'negative': data.ma5_dratio < 0,
          'neutral': data.ma5_dratio == 0,
        }
      ];
    },
    ma10dratio_priceClass(data) {
      return [
        {
          'positive': data.ma10_dratio > 0,
          'negative': data.ma10_dratio < 0,
          'neutral': data.ma10_dratio == 0,
        }
      ];
    },
    ma25dratio_priceClass(data) {
      return [
        {
          'positive': data.ma25_dratio > 0,
          'negative': data.ma25_dratio < 0,
          'neutral': data.ma25_dratio == 0,
        }
      ];
    },
    ma50dratio_priceClass(data) {
      return [
        {
          'positive': data.ma50_dratio > 0,
          'negative': data.ma50_dratio < 0,
          'neutral': data.ma50_dratio == 0,
        }
      ];
    },
    ma75dratio_priceClass(data) {
      return [
        {
          'positive': data.ma75_dratio > 0,
          'negative': data.ma75_dratio < 0,
          'neutral': data.ma75_dratio == 0,
        }
      ];
    }, 
    gapratio_priceClass(data) {
      return [
        {
          'positive': data.gap_ratio > 0,
          'negative': data.gap_ratio < 0,
          'neutral': data.gap_ratio == 0,
        }
      ];
    },
    volaratio_priceClass(data) {
      return [
        {
          'positive': data.vola_ratio > 0,
          'negative': data.vola_ratio < 0,
          'neutral': data.vola_ratio == 0,
        }
      ];
    },
    toggle_setMeigaraCd(event, mcd, mname){
      this.$refs.menu1.toggle(event);
      this.selectedMeigaraCd = mcd;
      this.selectedMeigaraName = mname;
    },
  }
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/prices.scss';

// .trendchart-img {
//   opacity: 0.8;
//   border: solid 1px #cccccc;
//   border-radius: 5px;  
//   width: 136px;
//   height: 35px;
// }
</style>